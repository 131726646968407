import { Settings } from '../app/shared/interfaces/interfaces';

const settings: Settings = {
  apiUrl: 'https://api.test.taxi.afaqy.pro/api/v1/'
};

export const environment = {
  production: false,
  settings
};
